export const bgDescriptionPrivacyAndLegal = `
<div>




<h1>Обща информация</h1>
<p>
Fit Fortis ("Сайт", "Услуга"), намиращ се на <a href="https://www.fitfortis.com"> www.fitfortis.com </a>
е защитен с авторски права сайт в Интернет, принадлежащ на Fit Fortis. Някои функции на Сайта могат да бъдат предмет на допълнителни указания,
условия или правила, които са публикувани на Сайта във връзка с такива функции. Всички такива допълнителни условия, насоки,
и правила са включени в секциите "Поверителност на информацията" и "Условия за ползване" по-долу.
</p>

<p>
Политиката за поверителност описва как Сайтът събира, съхранява, защитава и използва твоите лични данни.
</p>

<p>
Условията за ползване описват законово обвързващите условия, които контролират използването на Сайта.
ПРИ ВЛИЗАНЕ В САЙТА ИЛИ ИЗПОЛЗВАНЕ НА САЙТА, ТИ ПОКАЗВАШ БЕЗУСЛОВНО СЪГЛАСИЕ С ТЕЗИ УСЛОВИЯ. При несъгласие с 
някое от тези условия, не използвай Сайта.
<p>

<p align="center">***</p>




<h1>Поверителност на информацията</h1>
<h2>Събиране и използване на информация</h2>
<p>
За да предоставим услугите на Сайта, ние изискваме събирането на определени лични данни, като например име, имейл, здравна информация и други.
</p>

<h2>Криптиране на данните и сигурност</h2>
<p>
Цялата лична информация, както и личните тайни (пароли) се предават сигурно през HTTPS и се съхраняват криптирани в покой, 
в базите данни на Fit Fortis. Това означава, че нямаме достъп до никаква част от вашата лична информация - име, имейл, парола. 
Тя се съхранява в криптиран (шифрован) вид.
</p>

<p>
Ние ценим вашето доверие в предоставянето на лична информация и се стремим да използваме индустриални 
средства за защита от най-висок клас. В същото време е важно да се отбележи, че не съществува метод за предаване 
и съхранение на данни през Интернет, който да е 100% сигурен и надежден -- винаги съществува известен риск.
</p>

<h2>Доставчици на услуги</h2>
<p>
Сайтът използва услугите на корпорации и физически лица, с цел::
(i) развойни деиности,
(ii) осигуряване на базисната функционалност на Сайта,
(iii) анализ как се използва Сайта и Услугата.
</p>

<h2>Телеметрия и събиране на аналитични данни</h2>
<p>
Всички посещения на Сайта задействат събирането на телеметрична информация, която се изпраща от вашия браузър.
Тази информация може да включва адреса на вашия компютър (т. нар. "IP address"), езика на който използвате Сайта, версията на 
вашия браузър и операционна система, страниците на Сайта, които посещавате, часът и датата на посещението ви, времето, 
прекарано на тези страници, и други статистически данни. Данните се предават и съхраняват в криптиран и анонимизиран вид.
</p>

<H2>Бисквитки</h2>
<p>
Бисквитките (т. нар. "Cookies") са малки файлове, които обикновено се използват като анонимен уникален идентификатор. 
Бисквитките се генерират от всеки сайт в Интернет който посещавате и се съхраняват на твърдия диск на вашия компютър. 
Fit Fortis използва тези бисквитки с цел подобряване на Сайта и на Услугата, и с цел улесняване на използване им.
</p>

<p align="center">***</p>




<h1>Условия за ползване</h1>
<h2>Достъп до Сайта</h2>
<p>
<b>При спазване на настоящите Условия за ползване.</b> 
Fit Fortis ви предоставя непрехвърлим, неизключителен, отменяем, и ограничен лиценз за достъп до Сайта единствено 
за ваша лична, нетърговска употреба.
</p>
  
<p>
<b>Ограничения.</b> 
Правата, одобрени за вас в настоящите Условия за ползване, подлежат на следните ограничения:
(i) няма да продавате, давате под наем, прехвърляте, преотстъпвате, разпространявате, хоствате или по друг начин да експлоатирате по търговски начин Сайта;
(ii) няма да променяте, правите производни произведения на, дезасемблирате и/или разглобявате, преработвате обратно или ревизирате която и да е част от Сайта;
(iii) няма да използвате своя достъп до Сайта, за да създадете подобен или конкурентен уебсайт; и
(iv) с изключение на изрично посоченото тук, никоя част от Сайта не може да се копира, възпроизвежда, разпространява, преиздава, изтегля,
показва, публикува или предава под каквато и да е форма или по какъвто и да е начин, освен с изричното писмено съгласие на Fit Fortis. 
Всички авторски права и патенти на Сайта трябва да бъдат запазени и посочени във всички негови копия.
</p>

<p>
<b>Поддръжка и еволюция. </b> 
Fit Fortis си запазва правото да променя, прекъсва или прекратява всяка част от Сайта
със или без предизвестие до вас. Вие приемате, че Fit Fortis няма да носи отговорност пред вас или към трета страна за каквато и да е
промяна, прекъсване или прекратяване на Сайта или на която и да е част от Сайта.
</p>

<h2>Този сайт не предоставя медицински съвети</h2>
<p>
Съдържанието на Сайта, като текст, графики, изображения и други материали, създадени от
Fit Fortis или получени от Fit Fortis, както и други материали, съдържащи се на сайта на Fit Fortis
("Съдържание") са само с информационна цел. Съдържанието не е предназначено
да бъде заместител на професионални медицински съвети, диагноза или лечение. Винаги търсете
съветите на вашия лекар или друг квалифициран доставчик на здравеопазване с всякакви въпроси, които може да имате
по отношение на медицинско си състояние. Никога не пренебрегвайте професионалните медицински съвети и не 
забавяйте търсенето им поради нещо, което сте прочели в Сайта!
</p>

<p>
<p>
Ако смятате, че може да се нуждаете от спешна медицинска помощ, незабавно се обадете на лекар или на
Бърза Помощ. Fit Fortis не препоръчва и не одобрява специфични тестове, лекари, продукти, процедури,
мнения или друга информация, която може да бъде спомената на Сайта. Разчитане на всякаква информация
предоставена от Fit Fortis, служители на Fit Fortis, друго съдържание, появяващо се на Сайта,
или други посетители на Сайта е единствено на ваш собствен риск.
</p>

<h2>Информация генерирана от потребителя</h2>
<p>
<b>Информация генерирана от потребителя. </b> 
"Информация генерирана от потребителя" ("Информация") означава всякаква и цялата информация и съдържание, които потребителят предоставя на Сайта.
Вие носите изключителна отговорност за своята Информация. Вие носите всички рискове, свързани с използването на вашата Информация.
С настоящото удостоверявате, че вашата Информация не нарушава нашите Условия за приемлива информация по-долу. Не можете да твърдите, че 
вашата Информация по някакъв начин се предоставя, спонсорира или одобрява от Fit Fortis. 
</ P>

<p>
<b>Условия за приемлива информация. </b> 
Вие се съгласявате да не 
използвате Сайта за събиране, качване, предаване, показване или разпространение на потребителска Информация, която:
(i) нарушава правата на трета страна и/или която е чужда интелектуална собственост; 
(ii) е незаконна, насилствена, със заплашителен характер,
вредна, инвазивна за нечия лична неприкосновеност, вулгарна, клеветническа, лъжлива, умишлено подвеждаща, търговска злоупотреба, порнографска,
нецензурна, явно обидна, насърчава расизъм, фанатизъм, омраза или физически наранявания от всякакъв вид срещу всяка група или индивид;
(iii) е вредна за непълнолетни по някакъв начин; или 
(iv) нарушава закон, регламент или задължения или ограничения, наложени от която и да е трета страна.
</p>

<p>
В допълнение, вие се съгласявате да не: 
(i) качвате, предавате или разпространявате до или чрез Сайта софтуер, предназначен за
повреди или промени на компютърни системи или данни; 
(ii) изпращате чрез Сайта непоискана или неоторизирана реклама,
рекламни материали, нежелана поща, спам, верижни писма, пирамидни схеми или всяка друга форма на дубликат или
непоискани съобщения; 
(iii) използвате Сайта за събиране на информация или данни относно други потребители без тяхното съгласие; 
(iv) пречите, прекъсвате или да създавате ненужен трафик за сървърите или мрежите
свързани със Сайта или да нарушавате разпоредбите, политиките или процедурите на такива мрежи; 
(v) правите опити за неоторизиран достъп до Сайта, независимо дали чрез извличане на парола или други средства; 
(vi) затормозявате или пречите на използването на Сайта от други потребители; или 
(vi) използвате софтуер или автоматизирани агенти или скриптове, за да произвеждате множество
акаунти на Сайта или за генериране на автоматизирани търсения, или заявки към Сайта.
</p>

<p>
Fit Fortis си запазва правото да преглежда всяка потребителска Информация и да проучва и/или предприема подходящи действия срещу вас 
по наша преценка, ако нарушавате Условията за ползване или друга законова разпоредба или по друг начин
създавате отговорност за нас или други трети лице. Такива действия могат да включват премахване или промяна на потребителската ви Информация,
прекратяване на акаунта ви и/или докладване на органите на реда.
</p>

<p>
Вие се съгласявате да не държите Fit Fortis и неговите служители, и агенти под отговорност за разходи и
адвокатски хонорари, в резултат на искане, направено от което и да е трето лице поради или произтичащо от: 
(i) използването на сайта;
(ii) вашето нарушение на тези Условия за използване;
(iii) вашето нарушение на приложимите закони или разпоредби; или
(iv) вашата потребителска Информация.
</p>

<p align="center">***</p>




<h1>За контакти</h1>
<p>
Имейл: <a href="fit.fortis@outlook.com">fit.fortis@outlook.com</a>
</p>

	
</div>
`;
