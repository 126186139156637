export const bgDescriptionAbout = `
<div>




<h1>Относно</h1>
<p>
Fit Fortis е твоя безплатен персонализиран център за здраве. Fit Fortis предоставя красив, функционален и надежден сейф за цялата ти здравна информация:
</p>
<ul>
<li><b>Графики</b> - Записвай и проследявай различни метрики и здравни показатели. Извличай корелации между различните здравни индикатори, като показваш няколко графики на една диаграма. </li>
<li><b>Сейф за документи</b> - Нашият защитен сейф за документи ти позволява да съхраниш своите здравни документи, като здравни справки, застрахователни документи, медицински диагнози, медицински изображения, рецепти и формуляри. </li>
<li><b>Профил</b> - Запиши и съхранявай своите лични, генетични и наследствени данни в защитен кпритиран профил.</li>
<li><b>Новини</b> - Бъди информиран със персонализирани статии на различни здравни теми. </li>
<li><b>Известия</b> - Получавай навременни известия за всичко, свързано със здравето ти. </li>
<li><b>Енциклопедия</b> - Използвай изчерпателна енциклопедия с десетки хиляди статии за медицински термини и лекарства. </li>
</ul>

<p>
Всички данни на Сайта са <b>криптирани и шифровани в покой</b>. Това означава, че никой освен теб няма достъп до твоята лична информация - име, имейл, парола и т.н.
</p>




</div>
`;
