import bg from './bg-BG';
import enUs from './en-US';
import uk from './uk-UA';
import en from './en';

export default {
  'bg-bg': bg,
  'en-us': enUs,
  'uk-ua': uk,
  en
};
